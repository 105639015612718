<script setup lang="ts">
import Button from '@/views/components/ui/button/Button.vue'
import { usePage } from '@inertiajs/vue3'
import axios from 'axios'
import { getActiveLanguage, loadLanguageAsync } from 'laravel-vue-i18n'
import { route } from 'momentum-trail'
import { computed, ref } from 'vue'

const initialLanguage = getActiveLanguage()

const locales = usePage().props.locales

// try to match the initialLanguage to the available locales. Language is something like `en`, locale is `en_US`
const locale = ref(Object.keys(locales).find((locale: string) => {
    if (initialLanguage === 'no') {
        return locale === 'nb_NO'
    }

    return locale.startsWith(initialLanguage)
}) || 'en_GB')

async function setLocale(selectedLocale: string) {
    await loadLanguageAsync(selectedLocale)
    locale.value = selectedLocale

    await axios.put(route('locale.update', { locale: selectedLocale }))
}

const currentLocale = computed(() => locale.value)
const currentLanguage = computed(() => {
    if (locale.value === 'nb_NO') {
        return 'no'
    }

    return locale.value.split('_')[0]
})

defineExpose({
    currentLocale,
    currentLanguage,
})

function toggleHelpBeacon() {
    window.Beacon('toggle')
}
</script>

<template>
    <div class="my-8 flex gap-2 justify-between items-center">
        <div class="flex gap-2 items-center">
            <LanguageIcon class="w-5 h-5 text-gray-500" />
            <FormLabel class="sr-only">
                {{ $t('Language') }}
            </FormLabel>
            <div class="form-group col-span-6 sm:col-span-4">
                <FormSelect :model-value="locale" name="locale" :items="locales" @update:model-value="setLocale" />
            </div>
        </div>
        <Button size="sm" variant="ghost" class="flex gap-2" @click="toggleHelpBeacon">
            <LifebuoyIcon class="h-5 w-5 text-gray-500" />
            {{ $t('Help') }}
        </Button>
    </div>
</template>
